<!-- 取消待审核弹窗 -->
<template>
  <div>
    <el-dialog
      width="50%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      custom-class="ele-dialog-form"
      :title="isUpdate?'取消审核':'取消审核'"
      @update:visible="updateVisible">

      <el-form
        ref="form"
        :model="form"
        label-width="120px">
        <!-- 选项 -->
        <el-row>
          <div class="xuanxianglist">
            <el-radio-group v-model="telephone_type" @change="open">
              <el-radio :label="item.telephone_type" v-for="(item,index) in list">{{item.name}}</el-radio>
            </el-radio-group>
          </div>
        </el-row>

        <el-form-item label="处理取消：">
          <el-form-item label="上游是否报备：" label-width="130px">
            <el-radio v-model="radio2" label="1">是</el-radio>
            <el-radio v-model="radio2" label="2">否</el-radio>
          </el-form-item>
          <el-form-item label="客户是否核实：" label-width="130px">
            <el-radio v-model="radio3" label="1">是</el-radio>
            <el-radio v-model="radio3" label="2">否</el-radio>
          </el-form-item>
          <el-form-item label="订单是否继续执行：" label-width="130px">
            <el-radio v-model="radio4" label="1">是</el-radio>
            <el-radio v-model="radio4" label="2">否</el-radio>
          </el-form-item>
        </el-form-item>

          <!--<el-form-item label="取消是否收费：" label-width="130px">-->
          <!--    <el-radio v-model="radio5" label="1">是</el-radio>-->
          <!--    <el-radio v-model="radio5" label="2">否</el-radio>-->
          <!--</el-form-item>-->

          <el-form-item label="上游是否收费：" label-width="130px">
              <el-radio v-model="radio6" label="1">是</el-radio>
              <el-radio v-model="radio6" label="2">否</el-radio>
              <el-input placeholder="收款金额" v-model="form.input2" v-if="radio6 == '1'">
                  <template slot="append">元</template>
              </el-input>
          </el-form-item>

          <el-form-item label="下游是否收费：" label-width="130px">
              <el-radio v-model="radio7" label="1">是</el-radio>
              <el-radio v-model="radio7" label="2">否</el-radio>
              <el-input placeholder="收款金额" v-model="form.input3" v-if="radio7 == '1'">
                  <template slot="append">元</template>
              </el-input>
          </el-form-item>


        <el-form-item label="处理说明：">
          <el-input
            clearable
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 8}"
            placeholder="请输入内容"
            v-model="form.textarea">
          </el-input>
        </el-form-item>

      </el-form>

      <div slot="footer">
        <el-button
          @click="updateVisible(false)">取消
        </el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          v-auths="[`${$config.uniquePrefix}orderModule:info:followup`]"
          @click="save">确认取消
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="拨打电话"
      center
      :visible.sync="drawer"
      :show-close="false"
      width="30%"
      :before-close="handleClose">
      <div class="paddingbianju">
        <el-radio-group v-model="radio">
          <div class="xiabianju">
            <el-radio :label="1">小明 13103000257</el-radio>
          </div>
          <div class="xiabianju">
            <el-radio :label="2">小红 13103000257</el-radio>
          </div>
          <div class="xiabianju">
            <el-radio :label="3">小黑 13103000257</el-radio>
          </div>
        </el-radio-group>
      </div>
      <div slot="footer">
        <el-button class="rightmargin" @click="drawer = false">
          取消
        </el-button>
        <el-button type="primary" @click="queding" class="rightmargin">
          确认
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies, setCookies} from '@/utils/cookies'
import Config from '@/config'
// 引入的接口
import {order_follow} from '@/api/orderModule'
// 引入的接口
import {get_tel_no} from '@/api/phone'

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //司机相关
      list:[
        {
          name:'拨打车主电话',
          telephone_type:'owner'
        },
        {
          name:'拨打订单来源客户电话',
          telephone_type:'upstream'
        },
        {
          name:'拨打司机电话',
          telephone_type:'technician'
        },
        {
          name:'拨打服务商电话',
          telephone_type:'facilitator'
        }
      ],
      isShow:0,

      telephone_type:'owner',

      // 上传图片
      dialogImageUrl: '',
      dialogVisible: false,
      upload_btn: false, // 是否隐藏上传图片按钮
      fileList:[],
      // 上传接口
      action: setting.apiBaseURL + 'common/upload/capacity',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },

      // 电话抽屉
      drawer: false,
      direction: 'rtl',

      radio:1,

      radio2:'',
      radio3:'',
      radio4:'',
        radio5:'',
        radio6:'',
        radio7:'',

    };
  },


  mounted() {
    if (this.data) {
      console.log(this.data)
      this.form = Object.assign({}, this.data);
      this.form.remark = '';
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }
  },

  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
            this.loading = true;
            this.updateVisible(false);
            this.$emit('done');
            this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //选择类型
    activeItem(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
      this.telephone_type = item.telephone_type;
      if(item.name == '车主电话'){
        this.list[0].img = require('../../../../assets/images/home/genjindianhuaactive.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[3].img = require('../../../../assets/images/home/genjindianhua.png');
      }else if(item.name == '拨打订单来源客户电话'){
        this.list[0].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhuaactive.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[3].img = require('../../../../assets/images/home/genjindianhua.png');
      }else if(item.name == '拨打司机电话'){
        this.list[0].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhuaactive.png');
        this.list[3].img = require('../../../../assets/images/home/genjindianhua.png');
      }else if(item.name == '拨打服务商电话'){
        this.list[0].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[3].img = require('../../../../assets/images/home/genjindianhuaactive.png');
      }

    },


    open(){
      this.drawer = true;
    },

    handleClose() {
      this.drawer = false;
    },
    queding(){
      this.tel();
    },

    // 点击拨打电话
    tel(phone,tel_type){
      get_tel_no().then(res => {
        if(res.data.is_exist == 0){

          // 显示拨打电话弹窗
          let data = {
            showPhone: true
          }
          this.$addStorageEvent(1, "showPhone", JSON.stringify(data))
          // 要拨打的手机号
          let dianhua = {
            phone: phone
          }
          this.$addStorageEvent(1, "phone", JSON.stringify(dianhua))

          // 获取 订单号id
          let orderid = {
            orderid: this.data.id,
          }
          this.$addStorageEvent(1, "orderid", JSON.stringify(orderid))

          // 获取呼出类型
          let teltype = {
            tel_type: tel_type
          }
          this.$addStorageEvent(1, "tel_type", JSON.stringify(teltype))
        }else {
          this.$message.error('暂无坐席')
        }
      })
    },


  }
}
</script>

<style scoped lang="scss">
.xuanxianglist{
  margin-bottom: 20px;
  .xuanxianglistactive{
    width: 23%;
    cursor: pointer;
    background: #e8e8e8;
    border: 1px solid #dadada;
    border-radius: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #7b7b7b;
    text-align: center;
    margin-top: 0;
    margin-right: 10px;
    display: inline-block;
  }
  .xuanxianglistactive1{
    background: #FF9B05;
    color: #FFFFFF;
    border-color: #FF9B05;
  }

  //最后一个
  .xuanxianglistactive:last-child{
    margin-right: 0;
  }

}

.paddingbianju{
  //padding: 20px;
}

.phoneicon{
  width: 16px;height: 16px;margin-left: 10px;
}
.xiabianju{
  margin: 20px;
}
.rightmargin{
  margin-right: 20px;
}


.tianjiagenjin{
  width: 670px;
  font-size: 18px;
}

.topmargin{
  margin-top: 20px;
}
.genjinfont{
  width: 5px;height: 16px;background: #a3a4b2;display: inline-block;
}
.genjinfont1{
  font-size: 16px;font-weight: 500;color: #3f4155;margin-left: 10px;
}
.leftmargin{
  margin-left: 20px;
}
.leftmargin1{
  margin-left: 15px;
}

.huifustyle{
  margin-left: 20px;
}
</style>
